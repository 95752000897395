import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Container from "../components/Container";
import PageTitle from "../components/PageTitle";
import React from "react";
import InfiniteImages from "../components/InfiniteImages";


const Gallery = () => (
  <Layout>
    <SEO title="Carpets/Rugs Image Gallery" />
    <Container>
      <PageTitle>Carpets/Rugs Image Gallery</PageTitle>
      <InfiniteImages/>
    </Container>
  </Layout>
)
export default Gallery
