import React, { useState, useEffect } from "react";
import axios from "axios";
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import "./gallery.css"

const ImageGallery = ({ images, loading, fetchImages }) => {

  let imagesList = []

  // Create gallery here
  return (
    <SimpleReactLightbox>
    <InfiniteScroll
      dataLength={images.length}
      next={() => fetchImages()}
      hasMore
      scrollableTarget=""
      loader={
        <p style={{ textAlign: "center", marginTop: "1%"}}>
          Loading …
        </p>
      }
    >
      <SRLWrapper>
      <div className="image-grid">
        {!loading
        ? images.map(image => ( !imagesList.includes(image.id) ?
          <div className="image-item" key={image.id}>
            <img src={image.urls.regular} alt={image.alt_description} />
            <div className="hidden">{imagesList.push(image.id)}</div>
          </div> : ""
         )) : ""}
      </div>
      </SRLWrapper>
    </InfiniteScroll>
    </SimpleReactLightbox>
  )
}

const InfiniteImages = () => {
  // Hold State
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchImages()
  }, [])

  // API endpoint
  const endpoint = '/.netlify/functions/fetch'; // /.netlify/functions/fetch

  const fetchImages = () => {
    axios(endpoint).then(res => {
      setImages([...images, ...res.data.images])
      setLoading(false);
    });
  }

  return (
    <ImageGallery images={images} loading={loading} fetchImages={fetchImages}/>
  )
}

ImageGallery.propTypes = {
  images: PropTypes.array,
  loading: PropTypes.bool,
  fetchImages: PropTypes.func,
}

export default InfiniteImages
